.tooltip
  display: block !important
  max-width: 400px
  z-index: $z-index-tooltip
  .tooltip-inner
    background: black()
    color: white()
    border-radius: $radius-large
    padding: 5px 10px 4px
    +font('regular')
  .tooltip-arrow
    width: 0
    height: 0
    border-style: solid
    position: absolute
    margin: 5px
    border-color: black()
    z-index: 1
  &[x-placement^="top"]
    margin-bottom: 5px
    .tooltip-arrow
      border-width: 5px 5px 0 5px
      border-left-color: transparent !important
      border-right-color: transparent !important
      border-bottom-color: transparent !important
      bottom: -5px
      left: calc(50% - 5px)
      margin-top: 0
      margin-bottom: 0
  &[x-placement^="bottom"]
    margin-top: 5px
    .tooltip-arrow
      border-width: 0 5px 5px 5px
      border-left-color: transparent !important
      border-right-color: transparent !important
      border-top-color: transparent !important
      top: -5px
      left: calc(50% - 5px)
      margin-top: 0
      margin-bottom: 0
  &[x-placement^="right"]
    margin-left: 5px
    .tooltip-arrow
      border-width: 5px 5px 5px 0
      border-left-color: transparent !important
      border-top-color: transparent !important
      border-bottom-color: transparent !important
      left: -5px
      top: calc(50% - 5px)
      margin-left: 0
      margin-right: 0
  &[x-placement^="left"]
    margin-right: 5px
    .tooltip-arrow
      border-width: 5px 0 5px 5px
      border-top-color: transparent !important
      border-right-color: transparent !important
      border-bottom-color: transparent !important
      right: -5px
      top: calc(50% - 5px)
      margin-left: 0
      margin-right: 0
  &.popover
    .popover-inner
      background: black()
      color: white()
      padding: 24px
      border-radius: $radius
      box-shadow: 0 5px 30px rgba(black, .1)
      .button-close
        position: absolute
        top: 5px
        right: 5px
    .popover-arrow
      border-color: black()
  &[aria-hidden='true']
    visibility: hidden
    opacity: 0
    transition: opacity .15s, visibility .15s
  &[aria-hidden='false']
    visibility: visible
    opacity: 1
    transition: opacity .15s
  &:focus
    outline: none

+xs
  .tooltip
    max-width: auto
@font-face
  font-family: 'lf_icons'
  src: url('../assets/fonts/icons/ladiesfirst.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'playfair_display'
  src: url('../assets/fonts/logo/playfair-display-webfont.woff') format('woff')
  font-style: normal
  font-weight: 600
  font-display: swap

@font-face
  font-family: 'outfit'
  src: url('../assets/fonts/logo/outfit-webfont.woff') format('woff')
  font-style: normal
  font-weight: 500
  font-display: swap

@font-face
  font-family: 'zilla_regular'
  src: url('../assets/fonts/zilla/zillaslab-regular-webfont.woff2') format('woff2'), url('../assets/fonts/zilla/zillaslab-regular-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
 font-family: 'zilla_semibold'
 src: url('../assets/fonts/zilla/zillaslab-semibold-webfont.woff2') format('woff2'), url('../assets/fonts/zilla/zillaslab-semibold-webfont.woff') format('woff')
 font-weight: normal
 font-style: normal
